exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-page-template-index-jsx": () => import("./../../../src/components/page-template/index.jsx" /* webpackChunkName: "component---src-components-page-template-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-buy-or-contribute-jsx": () => import("./../../../src/pages/buy-or-contribute.jsx" /* webpackChunkName: "component---src-pages-buy-or-contribute-jsx" */),
  "component---src-pages-copyright-jsx": () => import("./../../../src/pages/copyright.jsx" /* webpackChunkName: "component---src-pages-copyright-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-options-jsx": () => import("./../../../src/pages/options.jsx" /* webpackChunkName: "component---src-pages-options-jsx" */)
}

